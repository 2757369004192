import { getApiUrl } from "../utilities/const";

const axios = require("axios").default;

export const fetchProfile = () => {
  const token = sessionStorage.getItem("token");

  return axios
    .get(getApiUrl("users/logged-in-user/"), {
      headers: { Authorization: `token ${token}` },
    })
    .then((res: any) => res?.data);
};

export interface UserProfile {
  first_name: string;
  last_name: string;
  id: string;
  has_student_photo_uploads_enabled: boolean;
  profile_picture: string;
  cover_photo: string;
  mi_profile: string;
}

export interface ActiveTime {
  monthly_activity_hours_total: number;
}

export interface LoginFormValues {
  username: string;
  password: string;
  school: string;
}
export const loginUser = (payload: LoginFormValues) =>
  axios.post(getApiUrl("auth/login/"), payload).then((res: any) => res?.data);

export interface LoginMicrosoftFormValues {
  school: any;
  access_token: any;
  code: any;
}

export interface ChangePasswordFormValues {
  new_password1: string;
  new_password2: string;
  old_password: string;
}

export interface SetPasswordFormValues {
  new_password1: string;
  new_password2: string;
  token: string;
  username: string;
}

export const changePassword = (payload: ChangePasswordFormValues) => {
  const token = sessionStorage.getItem("token");

  return axios
    .post(getApiUrl("auth/password/change/"), payload, {
      headers: { Authorization: `token ${token}` },
    })
    .then((res: any) => res?.data);
};

export const setPassword = (payload: SetPasswordFormValues) => {
  return axios
    .patch(getApiUrl("users/reset-password/"), payload, {
      headers: { Authorization: "" },
    })
    .then((res: any) => res?.data);
};

export const logoutUser = () => {
  const token = sessionStorage.getItem("token");

  return axios
    .post(getApiUrl("auth/logout/"), null, {
      headers: { Authorization: `token ${token}` },
    })
    .then((res: any) => res?.data);
};

export interface LoginMicrosoftFormValues {
  school: any;
  access_token: any;
  code: any;
}

export const loginGoogleUser = (payload: LoginMicrosoftFormValues) =>
  axios
    .post(getApiUrl("users/rest-auth/google/"), payload)
    .then((res: any) => res?.data);

export const loginMicrosoftUser = (payload: LoginMicrosoftFormValues) =>
  axios
    .post(getApiUrl("users/rest-auth/microsoft/"), payload)
    .then((res: any) => res?.data);

export const fetchSchools = (search: any) => {
  return axios
    .get(getApiUrl(`users/school-list/?search=${search}`))
    .then((res: any) => res?.data);
};

export const fetchSchoolBrand = () => {
  const token = sessionStorage.getItem("token");

  return axios
    .get(getApiUrl("users/school-brand/"), {
      headers: { Authorization: `token ${token}` },
    })
    .then((res: any) => res?.data);
};
