import { lazy, Suspense } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query'
import ProtectedRoute from './common/ProtectedRoute';
import SetPassword from './view/activityDetails/SetPassword';
import { GOOGLE_CLIENT_ID } from '../utilities/const';

import { GoogleOAuthProvider } from '@react-oauth/google';

const Loadable = (props: any) => <Suspense {...props} fallback={<LinearProgress />} />;
const queryClient = new QueryClient()

const Home = lazy(() => import('./screens/Home'));
const Onboarding = lazy(() => import('./screens/Onboarding'));
const Activity = lazy(() => import('./screens/Activity'));
const AddActivity = lazy(() => import('./screens/AddActivity'));

const ForgetPassword = lazy(() => import('./screens/ForgotPassword'));
// const SecurityCheck = lazy(() => import('./screens/securityCheck'));
// const ChangePassword = lazy(() => import('./view/activityDetails/changePassword'));
const ViewTutorials = lazy(() => import('./view/Home/viewTutorials'));
const ProfileScreen = lazy(() => import('./view/profile'));
const ActivityFeedScreen = lazy(() => import('./view/profile/activityFeed'));
const NotificationsScreen = lazy(() => import('./view/Home/notifications'));

const AppRoutes = () => (
	<QueryClientProvider client={queryClient}>
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
		<HashRouter>
		<Loadable>

			<Routes>
					<Route
						element={
							<Home />
						}
						path="/"
					/>
					<Route
						element={
							<ForgetPassword />
						}
						path="/forgot-password"
					/>
					{/* <Route
						element={<ProtectedRoute authenticationPath='/'><div style={{ margin: '10% 15%' }}>
							<ChangePassword firstTime={true} activeMenu={false} goBack={() => {}}/>
						</div>
						</ProtectedRoute>
						}
						path="/change-password"
					/> */}
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<Onboarding />
							</ProtectedRoute>
						}
						path="/onboarding"

					/>
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<Activity />
							</ProtectedRoute>
						}
						path="/home"

					/>
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<AddActivity />
							</ProtectedRoute>
						}
						path="/add-activity"

					/>
					{/* <Route
						element={
								<SecurityCheck />
						}
						path="/security"

					/> */}
					<Route
						element={
								<SetPassword />
						}
						path="/reset-password"

					/>
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<ViewTutorials />
							</ProtectedRoute>
						}
						path="/tutorials/:title"

					/>
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<ProfileScreen />
							</ProtectedRoute>
						}
						path="/profile"

					>
						
					</Route>
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<ActivityFeedScreen />
							</ProtectedRoute>
						}
						path="/activity-feed"

					/>
					<Route
						element={
							<ProtectedRoute authenticationPath='/'>
								<NotificationsScreen />
							</ProtectedRoute>
						}
						path="/notifications"

					/>
			</Routes>
			</Loadable>

		</HashRouter>
		</GoogleOAuthProvider>
	</QueryClientProvider>
);

export default AppRoutes;
